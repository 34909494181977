<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-row>
            <v-col cols="3"
                   v-for="(item, index) in modules"
                   v-bind:key="index"
                   class="viewCard">
                    <router-link :to="item.link">
                        <HoverCard :cardData="item"></HoverCard>
                    </router-link>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "Dashboard",
        watch: {},
        props: [
            'moduleName'
        ],
        components: {
            HoverCard
        },
        computed: {
            modules() {
                return [
                    {
                        name: this.$t('import_immigration_raw_excel_data'),
                        link: "/ImmigrationRawDataExcelImport",
                        icon: "mdi-file-import"
                    },
                    {
                        name: this.$t('import_immigration_raw_log_data'),
                        link: "/ImmigrationRawDataLogImport",
                        icon: "mdi-file-import"
                    },
                ]
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('import_immigration_raw_data'),
                        disabled: true,
                        exact: true
                    }
                    ]
            }
        },
        //data() {
        //    return {
        //        items: [
        //            {
        //                text: this.$t('reports'),
        //                exact: true,
        //                to: '/reportModules'
        //            },
        //            {
        //                text: this.$t('tourist_arrival'),
        //                disabled: true,
        //                exact: true
        //            }],
        //        }
       
        //},
        mounted() {
        },
        methods: {

        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>